//logo_gallery max 32rem

.logo_gallery {

  .logo_gallery_container {

    .logo_gallery_images {

      .logo_gallery_image_container {
        width: 50%;
        height: calc((50vw - 2rem) / 2);
      }
    }
  }
}