// blog_detail max 32rem

.blog_detail {

  .blog_detail_container {

    .blog_detail_part {

      .blog_detail_textandquote {

        .quote {
          width: 100%;
          padding: 0;
          margin-top: 4rem;
        }

        .text {
          width: 100%;
          padding: 0;
        }
      }

      .blog_detail_imageandquote {

        .image {
          width: 100%;
          height: 56vw;
          margin-left: 0;
          padding-right: 0;
        }

        .quote {
          width: 100%;
          padding: 0;
          margin-top: 4rem;
        }

        &.quoteleft {

          .image {
            height: 56vw;
            width: 100%;
          }
        }
      }
    }
  }
}